import {
    Button,
    ButtonGroup,
    CircularProgress,
    Divider,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/LoadingSpinner'
import Section from '../../components/Section'
import { companiesQueries, proceduresQueries, snapshotsQueries } from '../../networking'
import MethodologyPage from '../MethodologyPage'
import { Save as SaveIcon, Edit as EditIcon } from '@material-ui/icons'
import { getResistoClassImage } from '../../utils'
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import PaymentIcon from '@material-ui/icons/Payment'
import AlertDialog from '../../components/Dialogs/AlertDialog'
import { useSnackBarStore } from '../../stateManagement'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import GetAppIcon from '@material-ui/icons/GetApp'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import { backend_url } from '../../constants'
import FileSaver from 'file-saver'
import SocketIOClient from '../../components/SocketIOClient'
import { Alert } from '@material-ui/lab'
import { Description as DescriptionIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    overlay: {
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        zIndex: 2,
        borderRadius: '4px',
        backdropFilter: 'blur(10px)',
    },
    overlayText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        fontSize: '25px',
        color: 'white',
        transform: 'translate(-50%,-50%)',
        width: '100%',
    },
    registerdIcon: {
        fontSize: '12px',
        marginRight: theme.spacing(0.5),
    },
    dots: {
        marginRight: theme.spacing(1),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    smallButton: {
        maxWidth: 180,
        height: 40,
        lineHeight: 1.2,
    },
    buttonGroup: {
        marginBottom: theme.spacing(2),
        zIndex: 3,
    },
    resistoClassTitle: {
        marginBottom: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(3),
    },
}))

const dataPaths = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    CONFIRMED: 'CONFIRMED',
})

export const Panoramica = () => {
    const procedureId = useParams().procedureId
    const classes = useStyles()
    const { t } = useTranslation()
    const [currentDataPath, setCurrentDataPath] = React.useState(null)
    const [isOverlayActive, setIsOverlayActive] = React.useState(null)
    const [isConfirmUseCreditModalOpen, setIsConfirmUseCreditModalOpen] = React.useState(false)
    const [loadSocketIO, setLoadSocketIO] = React.useState(false)
    const [generationProgress, setGenerationProgress] = React.useState(null)
    const [reportUrl, setReportUrl] = React.useState()

    const showSnackBar = useSnackBarStore((state) => state.show)

    const {
        data: procedure,
        isLoading: isProcedureLoading,
        refetch: refetchProcedure,
    } = useQuery([proceduresQueries.getProcedure.name, procedureId], () =>
        proceduresQueries.getProcedure.fn(procedureId)
    )

    const {
        data: lastSnapshot,
        isLoading: isLastSnapshotLoading,
        refetch: refetchLastSnapshot,
    } = useQuery(
        [snapshotsQueries.getLastSnapshot.name, procedureId],
        () => snapshotsQueries.getLastSnapshot.fn(procedureId, 'resisto', 'VALUTAZIONE'),
        {
            onSuccess: (data) => {
                setReportUrl(data.document?.depot_url)
            },
            retry: false,
        }
    )

    const createSnapshotMutation = useMutation(
        () =>
            snapshotsQueries.createSnapshot.fn({
                procedureId: procedureId,
                procedure_owner: procedure.owner.id,
                methodology: 'resisto',
            }),
        {
            onError: () =>
                showSnackBar({
                    message: t('generic_error'),
                    severity: 'error',
                }),
            onSuccess: () => {
                refetchProcedure()
                refetchLastSnapshot()
                setCurrentDataPath({
                    inUse: dataPaths.CONFIRMED,
                    data: lastSnapshotData,
                })
            },
        }
    )

    const lastSnapshotData = lastSnapshot?.data.result_extra_info
    const workspaceData = procedure?.methodologies.resisto.workspace.result_extra_info

    useEffect(() => {
        if (procedure) {
            if (lastSnapshot) {
                setCurrentDataPath({
                    inUse: dataPaths.CONFIRMED,
                    data: lastSnapshotData,
                })
            } else {
                setCurrentDataPath({
                    inUse: dataPaths.IN_PROGRESS,
                    data: workspaceData,
                })
            }

            if (procedure.methodologies.resisto.is_unlocked === true) {
                setIsOverlayActive(false)
            } else {
                setIsOverlayActive(true)
            }
        }
    }, [procedure, lastSnapshot])

    const { mutate: handleCredits, isLoading: isHandleCreditsLoading } = useMutation(
        companiesQueries.handleCredits.fn,
        {
            onSuccess: () => {
                refetchProcedure()
            },
            onError: (e) => {
                showSnackBar({ message: e.data, severity: 'error' })
            },
        }
    )
    // console.log({ procedure })
    const getLabel = (label) => {
        switch (label) {
            case 'W':
                return 'Peso edificio [kn]'
            case 'piano_debole':
                return 'Livello debole'
            case 'crid':
                return 'Coefficente riduttivo'
            case 'Sa':
                return 'Accelerazione spettrale'
            case 'Sac':
                return 'Accelerazione spettrale ridotta'
            case 'PGAc':
                return label
            case 'pga_ratio':
                return 'Rapporto PGAc/PGAd'
            case 'weakness':
                if (procedure?.building_material === 'MASONRY') {
                    return 'Direzione debole'
                } else {
                    return 'Crisi'
                }
            case 'ALPHA_ADUC':
                return 'aDUC'
            case 'ALPHA_AD':
                return 'aD'
            case 'ALPHA_PM':
                return 'aPM'
            case 'ALPHA_DT':
                return 'aDT'
            default:
                break
        }
    }

    const getImage = (resisto_class) => {
        if (isOverlayActive && currentDataPath.inUse === dataPaths.IN_PROGRESS) {
            return getResistoClassImage('logo')
        } else {
            return getResistoClassImage(`${resisto_class}`)
        }
    }

    const downloadReport = (url, fileName) => {
        if (url) {
            FileSaver.saveAs(String(new URL(url, backend_url)), fileName)
        } else {
            setLoadSocketIO(true)
        }
    }

    function handleDocumentGenerationStatusChange(status) {
        if (!!status.error_message) {
            showSnackBar({
                message: t('report_download_error'),
                severity: 'error',
            })
            setLoadSocketIO(false)
            setGenerationProgress(null)
        } else if (status.current_step === status.total_steps) {
            setLoadSocketIO(false)
            setGenerationProgress(null)
            refetchLastSnapshot().then(resp => {
                refetchProcedure()
                downloadReport(resp.data.document.depot_url, resp.data.document.name);
            })
        } else {
            setGenerationProgress((status?.current_step / status?.total_steps) * 100)
        }
    }
    // console.log({ generationProgress })
    return (
        <MethodologyPage methodology="resisto">
            {isProcedureLoading ||
            isLastSnapshotLoading ||
            !currentDataPath ||
            isOverlayActive === null ? (
                <LoadingSpinner />
            ) : (
                <>
                    {loadSocketIO && (
                        <SocketIOClient
                            procedureId={procedureId}
                            methodology="resisto"
                            updateStatus={handleDocumentGenerationStatusChange}
                            withIntro={
                                lastSnapshot.company_reference === '60b8ff6acc5d9bed73a2bc3c' ||
                                lastSnapshot.company_snapshot.name === 'Enel Green Power Italia'
                            }
                        />
                    )}
                    <Section
                        xs={12}
                        direction="column"
                        style={{ position: 'relative' }}
                        paperStyle={{ padding: 0 }}
                    >
                        {/* OVERLAY CONSUMO CREDITO */}
                        {isOverlayActive && currentDataPath.inUse === dataPaths.IN_PROGRESS && (
                            <Grid className={classes.overlay}>
                                <Grid className={classes.overlayText}>
                                    <Grid container spacing={4}>
                                        <Grid item container alignItems="center" justify="center">
                                            <LockOpenOutlinedIcon /> &nbsp; {t('unlock_workplace')}
                                        </Grid>
                                        <Grid item container justify="center">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<PaymentIcon />}
                                                onClick={() => setIsConfirmUseCreditModalOpen(true)}
                                            >
                                                Use 1 credit
                                            </Button>
                                        </Grid>
                                        <AlertDialog
                                            open={isConfirmUseCreditModalOpen}
                                            setIsOpen={setIsConfirmUseCreditModalOpen}
                                            title={t('consumare 1 credito?')}
                                            text={t('questa azione non è reversibile')}
                                            primaryButton={t('conferma')}
                                            primaryButtonDisabled={isHandleCreditsLoading}
                                            onSubmitCb={() =>
                                                handleCredits({
                                                    action: 'use',
                                                    methodology: 'resisto',
                                                    procedure_id: procedure.id,
                                                })
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid className={classes.container}>
                            <Typography
                                variant="h4"
                                component="h1"
                                align="center"
                                className={classes.title}
                            >
                                {t('panoramica')}
                            </Typography>
                            <Grid container>
                                {/* SWITCH BOZZA/ULTIMI CONFERMATI */}
                                <Grid
                                    item
                                    container
                                    justify="flex-end"
                                    className={classes.buttonGroup}
                                >
                                    <ButtonGroup disableElevation variant="contained">
                                        <Button
                                            color={
                                                currentDataPath.inUse === dataPaths.IN_PROGRESS &&
                                                'secondary'
                                            }
                                            className={classes.smallButton}
                                            startIcon={<EditIcon />}
                                            onClick={() =>
                                                setCurrentDataPath({
                                                    inUse: dataPaths.IN_PROGRESS,
                                                    data: workspaceData,
                                                })
                                            }
                                        >
                                            {t('use_work_in_progress_methodology_data')}
                                        </Button>

                                        <Button
                                            color={
                                                currentDataPath.inUse === dataPaths.CONFIRMED &&
                                                'secondary'
                                            }
                                            style={
                                                !lastSnapshot
                                                    ? { backgroundColor: '#9ea9b9' }
                                                    : undefined
                                            }
                                            className={classes.smallButton}
                                            startIcon={<SaveIcon />}
                                            disabled={!lastSnapshot}
                                            onClick={() =>
                                                setCurrentDataPath({
                                                    inUse: dataPaths.CONFIRMED,
                                                    data: lastSnapshotData,
                                                })
                                            }
                                        >
                                            {t('use_latest_confirmed_methodology_data')}
                                        </Button>
                                    </ButtonGroup>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    justify="center"
                                    xs={12}
                                    className={classes.resistoClassTitle}
                                >
                                    <Typography variant="h6" align="center">
                                        {t('classe_resisto')}
                                    </Typography>
                                    <Typography className={classes.registerdIcon} align="center">
                                        ®
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        className={classes.dots}
                                    >
                                        :
                                    </Typography>
                                    <Typography variant="h6" align="center" color="secondary">
                                        {currentDataPath?.data?.full_result?.resisto_class || 'N/D'}
                                    </Typography>
                                </Grid>
                                {/* ERRORS ALERT */}
                                {/* al momento stampa un alert generico, quando il BE fornirà degli errori comprensibili uniformare a LA2 */}
                                {currentDataPath.inUse === dataPaths.IN_PROGRESS &&
                                    procedure.methodologies.resisto.workspace.errors?.[0] && (
                                        <Alert severity="error" style={{ width: '100%' }}>
                                            {t('errors_present')}
                                        </Alert>
                                    )}
                                {/* COLONNA SX */}
                                <Grid item container xs={7}>
                                    <Grid item container justify="center" xs={12}>
                                        <Grid item xs={10}>
                                            <img
                                                src={getImage(
                                                    currentDataPath.data?.full_result?.resisto_class
                                                )}
                                                alt=""
                                                className={classes.image}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justify="center">
                                        {currentDataPath.inUse === dataPaths.IN_PROGRESS ? (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<CheckOutlinedIcon />}
                                                onClick={() => createSnapshotMutation.mutate()}
                                                disabled={
                                                    !!isOverlayActive ||
                                                    !!procedure.methodologies.resisto.workspace
                                                        .errors[0]
                                                    || (procedure.methodologies.resisto.workspace.result === null)
                                                }
                                            >
                                                {t('conferma_dati')}
                                            </Button>
                                        ) : (
                                            <>
                                                {/* <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<DoneAllIcon />}
                                                    style={{
                                                        marginRight: '20px',
                                                        maxHeight: '40px',
                                                    }}
                                                    onClick={() => console.log('')}
                                                >
                                                    {t('consegna_pratica')}
                                                </Button> */}

                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    // startIcon={<GetAppIcon />}
                                                    style={{
                                                        maxHeight: '40px',
                                                    }}
                                                    startIcon={
                                                        generationProgress ? (
                                                            <CircularProgress
                                                                size={25}
                                                                variant="determinate"
                                                                value={generationProgress}
                                                            />
                                                        ) : (
                                                            <DescriptionIcon />
                                                        )
                                                    }
                                                    onClick={() =>
                                                        downloadReport(
                                                            reportUrl,
                                                            lastSnapshot?.document?.name
                                                        )
                                                    }
                                                >
                                                    {t('scarica_report')}
                                                </Button>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* COLONNA DX */}
                                <Grid item container xs={5}>
                                    {Object.keys(
                                        _.omit(currentDataPath.data, [
                                            'calculated_resisto_class',
                                            'full_result',
                                            'ALPHA_ADUC',
                                            'ALPHA_AD',
                                            'ALPHA_PM',
                                            'ALPHA_DT',
                                        ])
                                    ).map((key, i) => {
                                        // il piano debole deve corrispondere a se stesso + 1
                                        const value =
                                            currentDataPath.data[key] +
                                            (key === 'piano_debole' ? 1 : 0)
                                        return (
                                            <>
                                                <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    alignItems="center"
                                                    justify="space-between"
                                                >
                                                    <Grid item>
                                                        <Typography component="h5">
                                                            {`${getLabel(key)}: `}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item style={{ marginLeft: '20px' }}>
                                                        <Typography
                                                            component="h4"
                                                            color="secondary"
                                                        >
                                                            {`${
                                                                typeof value === 'number'
                                                                    ? value.toFixed(2)
                                                                    : value
                                                            }`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider
                                                    style={{
                                                        height: '1px',
                                                        width: '100%',
                                                        marginTop: '10px',
                                                        marginBottom: '15px',
                                                    }}
                                                />
                                            </>
                                        )
                                    })}
                                    <Grid container justify="space-between">
                                        {Object.keys(
                                            _.pick(currentDataPath.data, [
                                                'ALPHA_ADUC',
                                                'ALPHA_AD',
                                                'ALPHA_PM',
                                                'ALPHA_DT',
                                            ])
                                        ).map((key) => {
                                            const value = currentDataPath.data[key]
                                            return (
                                                <>
                                                    <Grid item>
                                                        <Typography component="h5">
                                                            {`${getLabel(key)}: `}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            component="h4"
                                                            color="secondary"
                                                        >
                                                            {`${
                                                                typeof value === 'number'
                                                                    ? value.toFixed(2)
                                                                    : value
                                                            }`}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Section>
                </>
            )}
        </MethodologyPage>
    )
}
