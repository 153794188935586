import {
    createSelect,
    numberType,
    createFieldArray,
    attachmentType,
    positiveNumberType, createOptions,
} from './utils'

export const misurazioni_model_generic = {
    h: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'altezza_interpiano',
    },
    Ps: {
        _type: 'numberUnit',
        unit: 'kN/m<sup>2</sup>',
        label: 'peso_solaio',
    },
    Atot: {
        _type: 'numberUnit',
        unit: 'm<sup>2</sup>',
        label: 'area_totale_livello',
    },
}

export const misurazioni_model_specific = {
    MASONRY: {
        Ax: {
            _type: 'numberUnit',
            unit: 'm<sup>2</sup>',
            label: 'area_asse_x',
        },
        Ay: {
            _type: 'numberUnit',
            unit: 'm<sup>2</sup>',
            label: 'area_asse_y',
        },
        Tr: {
            _type: 'numberUnit',
            unit: 'kN/m<sup>2</sup>',
            label: 'resistenza_tangenziale',
        },
    },
    REINFORCED_CONCRETE: {},
    PREFABRICATED: {
        beams_on_x_weight: {
            _type: 'numberUnit',
            unit: 'kN',
            step: 0.001
        },
        beams_on_y_weight: {
            _type: 'numberUnit',
            unit: 'kN',
            step: 0.001
        },
        claddings_weight: {
            _type: 'numberUnit',
            unit: 'kN',
            step: 0.001
        },
        covers_weight_mq: {
            _type: 'numberUnit',
            unit: 'kN/m<sup>2</sup>',
        },
    },
}

export const materiali_model = {
    MASONRY: {
        Pm: {
            _type: 'number',
            unit: 'kN/m<sup>3</sup>',
            label: 'peso_muratura',
        },
    },
    REINFORCED_CONCRETE: {
        Fc: {
            _type: 'number',
            unit: 'kPa',
            label: 'resistenza_compressione_ca',
        },
        Fy: {
            _type: 'number',
            unit: 'kPa',
            label: 'resistenza_trazione_acciaio',
        },
        Pm: {
            _type: 'number',
            unit: 'kN/m<sup>3</sup>',
            label: 'densita_ca',
        },
    },
    PREFABRICATED: {
        Fc: {
            _type: 'number',
            unit: 'kPa',
            label: 'resistenza_compressione_ca',
        },
        Fy: {
            _type: 'number',
            unit: 'kPa',
            label: 'resistenza_trazione_acciaio',
        },
        Pm: {
            _type: 'number',
            unit: 'kN/m<sup>3</sup>',
            label: 'densita_ca',
        },
        Ec: {
            _type: 'number',
            unit: 'kPa',
            label: 'modulo_elastico_ca',
        },
        brackets_fyd: {
            _type: 'number',
            unit: 'kPa',
        },
    },
}

export const n_pilastri_setti_model = {
    n: {
        ...numberType,
        label: 'n_totale',
        min: 1,
        props: {
            size: 'small',
            margin: 'dense',
            style: {
                width: 110,
                marginLeft: 8,
            },
        },
    },
}

export const images_pilastri_setti_model = {
    images: createFieldArray(attachmentType),
}

export const nodo_model = {
    type: {
        ...createSelect([
            {
                value: 'T',
                label: 'testa_piana',
            },
            {
                value: 'TP',
                label: 'testa_piana_perni',
            },
            {
                value: 'P',
                label: 'tasca',
            },
            {
                value: 'PP',
                label: 'tasca_perno',
            },
            {
                value: 'PPD',
                label: 'tasca_perno_dado',
            },
        ]),
        label: 'tipologia_nodo_trave_pilastro',
    },
    beam: {
        _type: 'onlyUIGroup',
        beam_baricenter_h: {
            _type: 'numberUnit',
            unit: 'm',
        },
        beam_baricenter_border_distance: {
            _type: 'numberUnit',
            unit: 'm',
        },
        beam_section_area: {
            _type: 'numberUnit',
            unit: 'm<sup>2</sup>',
        },
    },
    bars_if_present: {
        _type: 'onlyUIGroup',
        bars_count: positiveNumberType,
        bars_diameter: {
            _type: 'numberUnit',
            unit: 'm',
        },
    },
}

export const nodo_tasca_model = {
    pocket: {
        _type: 'onlyUIGroup',
        pocket_height: {
            _type: 'numberUnit',
            unit: 'm',
        },
        pocket_thickness: {
            _type: 'numberUnit',
            unit: 'm',
        },
    },
}

export const nodo_perni_model = {
    pins: {
        _type: 'onlyUIGroup',
        pins_count: positiveNumberType,
        pins_diameter: {
            _type: 'numberUnit',
            unit: 'm',
        },
    },
}

export const pilastro_tipo_model = {
    dx: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_x',
    },
    dy: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_y',
    },
    Asw: {
        _type: 'numberUnit',
        unit: 'mm<sup>2</sup>',
        label: 'area_staffe',
    },
    steel_cover: {
        _type: 'numberUnit',
        unit: 'm',
    },
    s: {
        _type: 'numberUnit',
        unit: 'mm',
        label: 'passo_staffe',
    },
    support: {
        ...createSelect(['INCASTRO_SCORREVOLE', 'MENSOLA']),
        label: 'tipologia_incastro',
    },
    Mpilx: {
        _type: 'numberUnit',
        unit: 'kNm',
    },
    Mpily: {
        _type: 'numberUnit',
        unit: 'kNm',
    },
}

export const pilastro_tipo_prefabricated_model = {
    pillar_contact_surface: {
        _type: 'numberUnit',
        unit: 'm<sup>2</sup>',
    },
}

export const pilastro_tipology_model = {
    // items_count: {
    //     ...numberType,
    //     label: 'n_pilastri_associati',
    //     min: 1,
    //     props: {
    //         size: 'small',
    //         margin: 'dense',
    //         variant: 'standard',
    //     },
    // },
    dx: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_x',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
    dy: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_y',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
}

export const pilastro_tipology_prefabricated_model = {
    pillar_contact_surface: {
        _type: 'numberUnit',
        unit: 'm<sup>2</sup>',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
    beam_baricenter_border_distance: {
        _type: 'numberUnit',
        unit: 'm',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
}

export const pilastro_tipology_prefabricated_tasca_model = {
    pocket_thickness: {
        _type: 'numberUnit',
        unit: 'm',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
    pocket_height: {
        _type: 'numberUnit',
        unit: 'm',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
}

export const pilastro_tipology_prefabricated_perno_model = {
    pins_count: {
        ...positiveNumberType,
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
}

export const setto_tipo_model = {
    dx: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_x',
    },
    dy: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_y',
    },
    Asw: {
        _type: 'numberUnit',
        unit: 'mm<sup>2</sup>',
        label: 'area_staffe',
    },
    s: {
        _type: 'numberUnit',
        unit: 'mm',
        label: 'passo_staffe',
    },
}

export const setto_tipology_model = {
    // items_count: {
    //     ...numberType,
    //     label: 'n_setti_associati',
    //     min: 1,
    //     props: {
    //         size: 'small',
    //         margin: 'dense',
    //         variant: 'standard',
    //     },
    // },
    dx: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_x',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
    dy: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'dimensione_y',
        props: {
            size: 'small',
            margin: 'dense',
            variant: 'standard',
        },
    },
}


export const reinforcedConcreteFloorModel = {
    lvl: {
        _type: 'hidden',
    },
    h: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'Altezza di interpiano'
    },
    Ps: {
        _type: 'numberUnit',
        unit: 'kN/m<sup>2</sup>',
        label: 'Peso del solaio'
    },
    Atot: {
        _type: 'numberUnit',
        unit: 'm<sup>2</sup>',
        label: 'Area totale del piano lorda'
    },
    N: {
        _type: 'number',
        label: 'N per calcolo dominio M/N',
        disabled: true
    },
    n_columns: {
        _type: 'number',
        min: 0,
        step: 1,
        label: 'numero_colonne'
    },
    columns: {
        ...createFieldArray({
            dx: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'dimensione_x',
            },
            dy: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'dimensione_y',
            },
        })
    },
    column_images: {
        ...createFieldArray({
            _type: "attachment"
        })
    },
    ref_column: {
        _type: 'group',
        support: {
            ...createSelect([
                "INC-INC S",
                "mensola"
            ]),
            label: "Tipologia di incastro"
        },
        Asw: {
            _type: "numberUnit",
            unit: "mm<sup>2</sup>",
            label: "Area staffe"
        },
        steel_cover: {
            _type: "numberUnit",
            unit: "m",
            label: "Copriferro"
        },
        s: {
            _type: "numberUnit",
            unit: "mm",
            label: "Passo delle staffe"
        },
        Mpilx: {
            _type: "numberUnit",
            unit: "kNm",
            label: "momento resistente X"
        },
        Mpily: {
            _type: "numberUnit",
            unit: "kNm",
            label: "momento resistente Y"
        },
        dx: {
            _type: 'numberUnit',
            unit: 'm',
            label: 'dimensione_x',
        },
        dy: {
            _type: 'numberUnit',
            unit: 'm',
            label: 'dimensione_y',
        },
    },
    n_piers: {
        _type: 'number',
        min: 0,
        step: 1,
        label: 'numero_setti'
    },
    piers: {
        ...createFieldArray({
            dx: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'dimensione_x',
            },
            dy: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'dimensione_y',
            },
        })
    },
    pier_images: {
        ...createFieldArray({
            _type: "attachment"
        })
    },
    ref_pier: {
        _type: "group",
        dx: {
            _type: 'numberUnit',
            unit: 'm',
            label: 'dimensione_x',
        },
        dy: {
            _type: 'numberUnit',
            unit: 'm',
            label: 'dimensione_y',
        },
        Asw: {
            _type: "numberUnit",
            unit: "mm<sup>2</sup>",
            label: "Area staffe"
        },
        s: {
            _type: "numberUnit",
            unit: "mm",
            label: "Passo delle staffe"
        }
    },
    material_data: {
        _type: "group",
        Fc: {
            _type: "number",
            unit: 'kPa'
        },
        Fy: {
            _type: "number",
            unit: 'kPa'
        },
        Pm: {
            _type: "number",
            unit: 'kN/m<sup>3</sup>',
        },
    }
}
export const prefabricatedFloorModel = {
    lvl: {
        _type: 'hidden',
    },
    h: {
        _type: 'numberUnit',
        unit: 'm',
        label: 'Altezza di interpiano'
    },
    Ps: {
        _type: 'numberUnit',
        unit: 'kN/m<sup>2</sup>',
        label: 'Peso del solaio'
    },
    Atot: {
        _type: 'numberUnit',
        unit: 'm<sup>2</sup>',
        label: 'Area totale del piano lorda'
    },
    beams_on_x_weight: {
        _type: 'numberUnit',
        unit: 'kN',
        label: 'Peso complessivo delle travi in direzione x'
    },
    beams_on_y_weight: {
        _type: 'numberUnit',
        unit: 'kN',
        label: 'Peso complessivo travi in direzione y (orditura)'
    },
    claddings_weight: {
        _type: 'numberUnit',
        unit: 'kN',
        label: 'Peso complessivo delle tamponature'
    },
    covers_weight_mq: {
        _type: 'numberUnit',
        unit: 'kN/m<sup>2</sup>',
        label: 'Peso delle coperture al metro quadro'
    },
    N: {
        _type: 'number',
        label: 'N per calcolo dominio M/N',
        disabled: true
    },
    n_columns: {
        _type: 'number',
        step: 1,
        label: ''
    },
    columns: {
        ...createFieldArray({
            dx: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'Dimensione X'
            },
            dy: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'Dimensione Y'
            },
            pillar_contact_surface: {
                _type: 'numberUnit',
                unit: 'm<sup>2</sup>',
                label: 'Superficie di contatto nodo/pilastro'
            },
            beam_baricenter_border_distance: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'Dist. baricentro/centro di rotazione trave [m]'
            },
            pocket_thickness: {
                _type: 'numberUnit',
                unit: 'm<sup>2</sup>',
                label: 'Spessore della tasca - se presente'
            },
            pocket_height: {
                _type: 'numberUnit',
                unit: 'm<sup>2</sup>',
                label: 'Altezza della tasca'
            },
            pins_count: {
                _type: 'number',
                step: 1,
                min: 0,
                label: 'Numero di perni - se presenti'
            },
        })
    },
    column_images: {
        ...createFieldArray({_type: 'attachment'})
    },
    ref_column: {
        _type: 'group',
        support: createSelect([
            "INC-INC S",
            "mensola"
        ]),
        dx: {
            _type: 'numberUnit',
            unit: 'm',
            label: 'dimensione_x',
        },
        dy: {
            _type: 'numberUnit',
            unit: 'm',
            label: 'dimensione_y',
        },
        pillar_contact_surface: {
            _type: 'numberUnit',
            unit: 'm<sup>2</sup>',
            label: 'Superficie di contatto nodo/pilastro'
        },
        Asw: {
            _type: "numberUnit",
            unit: "mm<sup>2</sup>",
            label: "Area staffe"
        },
        steel_cover: {
            _type: "numberUnit",
            unit: "m",
            label: "Copriferro"
        },
        s: {
            _type: "numberUnit",
            unit: "mm",
            label: "Passo delle staffe"
        },
        Mpilx: {
            _type: "numberUnit",
            unit: "kNm",
            label: "momento resistente X"
        },
        Mpily: {
            _type: "numberUnit",
            unit: "kNm",
            label: "momento resistente Y"
        },
        node: {
            _type: "group",
            isNested: {
                _type: "boolean"
            },
            pins_diameter: {
                _type: 'numberUnit',
                unit: 'm',
                label: "Diametro del perno (o perni) (se presenti)"
            },
            pins_count: {
                _type: 'number',
                min: 0,
                step: 1,
                label: "N° dei perni (se presenti)"
            },
            pocket_height: {
                _type: 'numberUnit',
                unit: 'm<sup>2</sup>',
                min: 0,
                label: 'Altezza della tasca'
            },
            pocket_thickness: {
                _type: 'numberUnit',
                unit: 'm<sup>2</sup>',
                min: 0,
                label: 'Spessore della tasca - se presente'
            },
            bars_count: {
                _type: "number",
                step: 1,
                min: 0,
                label: "N° barre (se presenti)"
            },
            bars_diameter: {
                _type: 'numberUnit',
                unit: 'm',
                min: 0,
                label: "Diametro delle barre (se presenti)"
            },
            beam_baricenter_h: {
                _type: 'numberUnit',
                unit: 'm',
                label: "Altezza baricentro trave rispetto al punto d'appoggio"
            },
            beam_baricenter_border_distance: {
                _type: 'numberUnit',
                unit: 'm',
                label: 'Dist. baricentro/centro di rotazione trave'
            },
            beam_section_area: {
                _type: 'numberUnit',
                unit: 'm<sup>2</sup>',
                label: "Area di sezione della trave"
            },
            type: {
                ...createSelect(["T", "TP", "P", "PP", "PPD"])
            }
        },
    },
    n_piers: {
        _type: 'number',
        step: 1,
        min: 0,
        label: ''
    },
    piers: {
        ...createFieldArray({
            dx: {
                _type: 'numberUnit',
                unit: 'm',
                min: 0,
                label: 'dimensione_x',
            },
            dy: {
                _type: 'numberUnit',
                unit: 'm',
                min: 0,
                label: 'dimensione_y',
            },
        })
    },
    pier_images: {
        ...createFieldArray({_type: 'attachment'})
    },
    ref_pier: {
        _type: "group",
        dx: {
            _type: 'numberUnit',
            unit: 'm',
            min: 0,
            label: 'dimensione_x',
        },
        dy: {
            _type: 'numberUnit',
            unit: 'm',
            min: 0,
            label: 'dimensione_y',
        },
        Asw: {
            _type: "numberUnit",
            unit: "mm<sup>2</sup>",
            min: 0,
            label: "Area staffe"
        },
        s: {
            _type: "numberUnit",
            unit: "mm",
            min: 0,
            label: "Passo delle staffe"
        }
    },
    material_data: {
        _type: "group",
        __meta: {hideGroupUI: true},
        Fc: {
            _type: "number",
            unit: 'kPa'
        },
        Fy: {
            _type: "number",
            unit: 'kPa'
        },
        Pm: {
            _type: "number",
            unit: 'kN/m<sup>3</sup>',
        },
        Ec: {
            _type: "number",
            unit: 'kPa'
        },
        brackets_fyd: {
            _type: "number",
            unit: 'kPa'
        },
    }
}
export const masonryFloorModel = {
    lvl: {
        _type: 'hidden'
    },
    h: {
        _type: "numberUnit",
        unit: "m",
        label: "Altezza di interpiano"
    },
    Ax: {
        _type: "numberUnit",
        unit: "m",
        label: "Area degli elementi resistenti sull'asse x"
    },
    Ay: {
        _type: "numberUnit",
        unit: "m",
        label: "Area degli elementi resistenti sull'asse y"
    },
    Tr: {
        _type: "numberUnit",
        unit: "kN/m<sup>2</sup>",
        label: "Resistenza tangenziale media della muratura del piano"
    },
    Ps: {
        _type: "numberUnit",
        unit: "kN/m<sup>2</sup>",
        label: "Peso del solaio"
    },
    Atot: {
        _type: "numberUnit",
        unit: "m<sup>2</sup>",
        label: "Area totale del piano lorda"
    },
    material_data: {
        _type: "group",
        __meta: {hideGroupUI: true},
        Pm: {
            _type: "number",
            label: "peso_muratura",
            unit: 'kN/m<sup>3</sup>',
        }
    }
}
